<!-- 学分管理 -->
<template>
    <div class="scoreBox">
        <div class="myTabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="userInfo.currentProject.name" name="study">
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="addScore">
            <el-button type="text" size="small" style="margin-left:10px" @click="addScore">+添加学分制度</el-button>
        </div>
        <!-- 表格 -->
        <template v-if="tableData.length > 0">
            <div class="mid-bot">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column align="center" prop="title" label="标题"></el-table-column>
                    <el-table-column align="center" label="项目名称">{{ $store.state.user.currentProject.name
                    }}</el-table-column>
                    <el-table-column align="center" label="适用时间">{{ time }}</el-table-column>
                    <el-table-column align="center" prop="type" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" style="margin-left:10px" @click="view(scope.row)">查看
                            </el-button>
                            <el-button type="text" size="small" style="margin-left:10px" @click="edit(scope.row)">编辑
                            </el-button>
                            <el-button type="text" size="small" style="margin-left:10px"
                                :style="{ color: scope.row.publish == 1 ? '#F56C6C' : '' }" @click="issue(scope.row)">
                                {{ scope.row.publish == 0 ? '发布' : '取消发布' }}</el-button> <br>
                            <el-button type="text" size="small" style="margin-left:10px" @click="delScore(scope.row)">删除
                            </el-button>
                            <el-button type="text" size="small" style="margin-left:10px" @click="refresh(scope.row)">计算学分
                            </el-button>
                            <el-button type="text" size="small" style="margin-left:10px" @click="downLoad(scope.row)">下载
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="myPages flex-align-around">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page.sync="currentPage" :page-sizes="[8, 10, 20]" :page-size="8"
                    layout=" prev, pager, next,sizes" :total="total" background>
                </el-pagination>
            </div>
        </template>
        <el-empty description="暂无内容" v-else></el-empty>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            activeName: 'study',
            tableData: [],
            currentPage: 1,
            limit: 10,
            userInfo: this.$store.state.user,
            time: '',
            projectName: '',
            total: 0
        }
    },
    methods: {
        handleClick() {
            // this.$Api.Score.
        },
        handleSizeChange() {
            console.log('每页个数--')
        },
        handleCurrentChange() {
            console.log('tiaoye')
        },
        addScore() {
            this.$router.push({
                path: '/addScore?breadNumber=4',
                query: {
                    type: 'add'
                }
            })
        },
        delScore(item) {
            console.log(item);
            this.$confirm('您确认删除该学分规则吗?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: item.id
                }
                this.$Api.Score.delScore(data)
                    .then(res => {
                        console.log('删除成功:', res);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList()
                    })
                    .catch(err => {
                        console.log('删除失败:', err)
                    })

            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '取消发布'
                // });
            });

        },
        issue(item) {
            this.$confirm(`您确认${item.publish == 0 ? '发布' : '取消发布'}该学分规则吗?`, '发布', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: item.id,
                    publish: item.publish == 1 ? 0 : 1
                }
                this.$Api.Score.publishScore(data)
                    .then(res => {
                        console.log('发布成功:', res);
                        this.$message({
                            type: 'success',
                            message: `${item.publish == 0 ? '发布' : '取消发布'}成功!`
                        });
                        this.getList()
                    })
                    .catch(err => {
                        console.log(`${item.publish == 0 ? '发布' : '取消发布'}失败:`, err);
                        this.$message.error(`${item.publish == 0 ? '发布' : '取消发布'}失败,请联系管理员`);
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消发布'
                });
            });
        },
        view(item) {
            let query = {
                id: item.id,
                type: 'view'
            }
            this.$router.push({
                path: '/addScore?breadNumber=4',
                query
            })
        },
        edit(item) {
            let query = {
                id: item.id,
                type: 'edit'
            }
            this.$router.push({
                path: '/addScore?breadNumber=4',
                query
            })
        },
        getList() {
            let data = {
                projectId: this.$store.state.user.currentProject.id,
                page: this.currentPage,
                limit: this.limit
            }
            this.$Api.Score.getScoreList(data)
                .then(res => {
                    console.log('学分列表:', res);
                    this.total = res.data.total;
                    this.tableData = res.data.records;
                })
                .catch(err => {
                    console.log('获取学分列表失败:', err)
                })
        },
        getProjectInfo() {
            let data = {
                projectId: this.$store.state.user.currentProject.id
            }
            this.$Api.Score.projectInfo(data)
                .then(res => {
                    console.log('res:', res);
                    this.time = res.data.periodStartTime.split(' ')[0] + '-' + res.data.periodFinishTime.split(' ')[0];
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        downLoad(row) {
            // console.log('row:',row);
            window.open(encodeURI(axios.defaults.baseURL + `/score/sc/export?projectId=${this.$store.state.user.currentProject.id}&ruleId=${row.id}`))
        },
        async refresh(row){
            let data = {
                ruleId:row.id,
                projectId:this.$store.state.user.currentProject.id
            }
            let resData = await this.$Api.Score.refreshSc(data);
            console.log('刷新结果:',resData)
        }
    },
    mounted() {
        this.getList();
        this.getProjectInfo()
    },
};
</script>
<style lang='less' scoped>
.scoreBox {
    .addScore {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
    }

    .myPages {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
</style>