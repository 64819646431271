<!-- 添加学分制度 -->
<template>
    <div class="addScore">
        <div class="step1" v-show="currentStep == 'step1'">
            <el-form ref="form" :model="form" label-width="80px" label-position="left">
                <el-form-item label="标题" prop="name">
                    <el-input v-model="form.name" :disabled='disabled'></el-input>
                </el-form-item>
                <el-form-item label="适用人员" v-if="person.length > 0">
                    <el-checkbox-group v-model="form.persons">
                        <el-checkbox :disabled='disabled' :label="item.value" v-for="(item, index) in person"
                            :key="index">{{ item.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="总成绩">
                    <el-radio-group v-model.number="form.resource">
                        <el-radio :label="0" :disabled='disabled'>总分</el-radio>
                        <el-radio :label="1" :disabled='disabled'>学分</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div class="scoreBox flex-align">
                    <el-input v-model.number="score" @input="setScore(score)" :disabled='disabled'></el-input>
                    <span>{{ form.resource == 0 ? '分' : '学分' }}</span>
                </div>
                <el-form-item>
                    <el-button type="primary" @click="next">下一步</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="step2" v-show="currentStep == 'step2'">
            <div class="menu">
                <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
                    <!-- <el-radio-button label="async">异步课程</el-radio-button> -->
                    <!-- <el-radio-button label="sync">同步课程</el-radio-button> -->
                    <el-radio-button label="task">作业提交</el-radio-button>
                    <!-- <el-radio-button label="join">参与活动</el-radio-button> -->
                    <!-- <el-radio-button label="resource">资源应用</el-radio-button> -->
                    <el-radio-button label="attendance">考勤</el-radio-button>
                    <el-radio-button label="gsw">观思问</el-radio-button>
                    <el-radio-button label="work">调研问卷</el-radio-button>
                </el-radio-group>
            </div>
            <!-- 异步课程 -->
            <el-form ref="form" v-show="tabPosition == 'async'" :model="asyncForm" label-width="80px" label-position="left">
                <el-form-item label="总分值">
                    <div class="flex-align">
                        <el-input v-model.number="asyncForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <el-form-item label="评价标准">
                    <el-radio-group v-model="evaluateType" style="margin-bottom: 30px;" :disabled='disabled'>
                        <el-radio-button label="time">时长</el-radio-button>
                        <el-radio-button label="number">数量</el-radio-button>
                    </el-radio-group>
                    <div class="itemBox flex-align">
                        <el-form-item class="rule_item" :label="evaluateType == 'time' ? '观看时长' : '观看个数'">
                            <div class="flex-align">
                                <div class="flex-align">
                                    <el-input v-model.number="asyncForm.detailRules[0].ruleNum" size="mini"
                                        :disabled='disabled'></el-input>
                                    {{ evaluateType == 'time' ? 'min' : '个' }}
                                </div>
                                <div class="flex-align" style="margin-left:10px">
                                    <el-input v-model.number="asyncForm.detailRules[0].ruleValue" size="mini"
                                        :disabled='disabled'>
                                    </el-input>
                                    {{ evaluateType == 'time' ? '分/学分' : '学分' }}
                                </div>
                            </div>
                        </el-form-item>
                        <i class="el-icon-circle-plus-outline" @click="addItem('async')"></i>
                    </div>
                    <el-form-item :label="evaluateType == 'time' ? '观看时长' : '观看个数'"
                        v-for="(item, index) in asyncForm.detailRules.slice(1)" :key="index">
                        <div class="flex-align">
                            <div class="flex-align">
                                <el-input v-model.number="item.ruleNum" size="mini" :disabled='disabled'></el-input>
                                {{ evaluateType == 'time' ? 'min' : '个' }}
                            </div>
                            <div class="flex-align" style="margin-left:10px">
                                <el-input v-model.number="item.ruleValue" size="mini" :disabled='disabled'></el-input>
                                {{ evaluateType == 'time' ? '分/学分' : '学分' }}
                            </div>
                        </div>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="达标分数">
                    <div class="flex-align">
                        <el-input v-model="asyncForm.achievement" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
            </el-form>
            <!-- 同步课程 -->
            <el-form ref="form" v-show="tabPosition == 'sync'" :model="syncForm" label-position="left">
                <el-form-item label="总分值:">
                    <div class="flex-align">
                        <el-input v-model="syncForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <div class="flex-align">
                    <el-form-item label="直播评价标准:" style="margin-right:30px">
                        <div class="flex-align sync-box">
                            <div class="complete">每完成</div>
                            <el-input v-model.number="syncForm.detailRules[0].ruleNum" size="mini" style="margin-left:10px"
                                :disabled='disabled'></el-input>个
                        </div>
                    </el-form-item>
                    <el-form-item label="分值:" style="margin-left:30px">
                        <div class="flex-align sync-box">
                            <el-input v-model.number="syncForm.detailRules[0].ruleValue" size="mini"
                                :disabled='disabled'></el-input>
                            <div class="complete">分/学分</div>
                        </div>
                    </el-form-item>
                </div>
                <div class="flex-align">
                    <el-form-item label="回放评价标准:" style="margin-right:30px">
                        <div class="flex-align sync-box">
                            <div class="complete">每完成</div>
                            <el-input v-model.number="syncForm.detailRules[1].ruleNum" size="mini" style="margin-left:10px"
                                :disabled='disabled'></el-input>个
                        </div>
                    </el-form-item>
                    <el-form-item label="分值:" style="margin-left:30px">
                        <div class="flex-align sync-box">
                            <el-input v-model.number="syncForm.detailRules[1].ruleValue" size="mini"
                                :disabled='disabled'></el-input>
                            <div class="complete">分/学分</div>
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="达标分数:">
                    <div class="flex-align">
                        <el-input v-model.number="syncForm.achievement" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
            </el-form>
            <!-- 作业提交 -->
            <el-form ref="form" v-show="tabPosition == 'task'" :model="workForm" label-width="50px" label-position="left">
                <el-form-item label="作业">
                    <div class="flex-align work-box">
                        <el-select v-model="workForm.detailRules[0].ruleNum" placeholder="请选择" size="mini">
                            <el-option v-for="item in workList" :key="item.questId" :label="item.questName"
                                :value="item.questId">
                            </el-option>
                        </el-select>
                        <el-input v-model.number="workForm.detailRules[0].ruleValue" size="mini"
                            :disabled='disabled'></el-input>分
                        <i class="el-icon-circle-plus-outline" @click="addItem('work')"></i>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <el-radio-group v-model.number="workForm.detailRules[0].valueType">
                        <el-radio :label="0" :disabled='disabled'>设置分数</el-radio>
                        <el-radio :label="1" :disabled='disabled'>作业分数</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div v-for="(item, index) in workForm.detailRules.slice(1)" :key="index">
                    <el-form-item label="作业">
                        <div class="flex-align work-box">
                            <el-select v-model="item.ruleNum" placeholder="请选择" size="mini" :disabled='disabled'>
                                <el-option v-for="items in workList" :key="items.questId" :label="items.questName"
                                    :value="items.questId">
                                </el-option>
                            </el-select>
                            <el-input v-model.number="item.ruleValue" size="mini" :disabled='disabled'></el-input>分
                            <!-- <i class="el-icon-circle-plus-outline" @click="addItem('work')"></i> -->
                            <i class="el-icon-remove-outline" @click="delItem(index)"></i>
                        </div>
                    </el-form-item>
                    <el-form-item label="">
                        <el-radio-group v-model.number="item.valueType">
                            <el-radio :label="0" :disabled='disabled'>设置分数</el-radio>
                            <el-radio :label="1" :disabled='disabled'>作业分数</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <!-- <el-form-item label="总分值:">
                    <div class="flex-align">
                        <el-input v-model.number="taskForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <div class="flex-align">
                    <el-form-item label="评价标准:" style="margin-right:30px">
                        <div class="flex-align">
                            <span>每完成</span>
                            <el-input v-model.number="taskForm.detailRules[0].ruleNum" size="mini" style="margin-left:10px"
                                :disabled='disabled'></el-input>个
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="flex-align">
                            分值:
                            <el-input v-model.number="taskForm.detailRules[0].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'></el-input>分/学分
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="达标分数:">
                    <div class="flex-align">
                        <el-input v-model.number="taskForm.achievement" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item> -->
            </el-form>
            <!-- 参与活动 -->
            <el-form ref="form" v-show="tabPosition == 'join'" :model="joinForm" label-width="80px" label-position="left">
                <el-form-item label="总分值:">
                    <div class="flex-align">
                        <el-input v-model.number="joinForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <div class="flex-align">
                    <el-form-item label="评价标准:" style="margin-right:30px">
                        <div class="flex-align">
                            <span>参与活动</span>
                            <el-input v-model.number="joinForm.detailRules[0].ruleNum" size="mini" style="margin-left:10px"
                                :disabled='disabled'></el-input>
                            个
                        </div>
                        <div class="flex-align">
                            <span>创建活动</span>
                            <el-input v-model.number="joinForm.detailRules[1].ruleNum" size="mini" style="margin-left:10px"
                                :disabled='disabled'>
                            </el-input>
                            个
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="flex-align">
                            分值:
                            <el-input v-model.number="joinForm.detailRules[0].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>分/学分
                        </div>
                        <div class="flex-align">
                            分值:
                            <el-input v-model.number="joinForm.detailRules[1].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            分/学分
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="达标分数:">
                    <div class="flex-align">
                        <el-input v-model.number="joinForm.achievement" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
            </el-form>
            <!-- 资源应用 -->
            <el-form ref="form" v-show="tabPosition == 'resource'" :model="resourceForm" label-width="80px"
                label-position="left">
                <el-form-item label="总分值:">
                    <div class="flex-align">
                        <el-input v-model.number="resourceForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <div class="flex-align">
                    <el-form-item label="评价标准:" style="margin-right:30px">
                        <div class="flex-align">
                            <span>上传</span>
                            <el-input v-model.number="resourceForm.detailRules[0].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            个
                        </div>
                        <div class="flex-align">
                            下载<el-input v-model.number="resourceForm.detailRules[1].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            个
                        </div>
                        <div class="flex-align">
                            分享<el-input v-model.number="resourceForm.detailRules[2].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            个
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="flex-align">
                            分值
                            <el-input v-model.number="resourceForm.detailRules[0].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            分/学分
                        </div>
                        <div class="flex-align">
                            分值
                            <el-input v-model.number="resourceForm.detailRules[1].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            分/学分
                        </div>
                        <div class="flex-align">
                            分值
                            <el-input v-model.number="resourceForm.detailRules[2].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            分/学分
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="达标分数:">
                    <div class="flex-align">
                        <el-input v-model.number="resourceForm.achievement" size="mini" :disabled='disabled'></el-input>
                        分
                    </div>
                </el-form-item>
            </el-form>
            <!-- 考勤 -->
            <el-form ref="form" v-show="tabPosition == 'attendance'" :model="addtendanceForm" label-position="top">
                <el-form-item :label="stageTypes[item.stageType]" v-for="(item, index) in addtendanceForm.stageList"
                    :key="index">
                    <div class="gengang-box flex-column">
                        <span>住宿考勤：共{{ item.stayCount }}次</span>
                        <div class="flex-align">
                            {{ form.resource == 0 ? '总分值' : '总学分' }}：
                            <el-input v-model.number="item.stayScore" size="mini" :disabled='disabled'></el-input>
                            分
                        </div>
                    </div>
                    <div class="gengang-box flex-column">
                        <span>跟岗考勤：共{{ item.trackCount }}次</span>
                        <div class="flex-align">
                            {{ form.resource == 0 ? '总分值' : '总学分' }}：
                            <el-input v-model.number="item.trackScore" size="mini" :disabled='disabled'></el-input>
                            分
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <!-- 观思问 -->
            <el-form ref="form" v-show="tabPosition == 'gsw'" :model="gswForm" label-position="top">
                <el-form-item :label="`【${stageTypes[item.stageType]}】`" v-for="(item, index) in gswForm.stageList"
                    :key="index">
                    <div class="gsw-box flex-column">
                        <div class="count-box flex-align">
                            <span>听：共{{ item.answerCount }}次</span>
                            <span>思：共{{ item.answerCount }}次</span>
                            <span>问：共{{ item.answerCount }}次</span>
                        </div>
                        <div class="flex-align">
                            {{ form.resource == 0 ? '总分值' : '总学分' }}：
                            <el-input v-model.number="item.score" size="mini" :disabled='disabled'></el-input>
                            分
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <!-- 调研问卷 -->
            <el-form ref="form" v-show="tabPosition == 'work'" :model="questForm" label-position="top">
                <el-form-item label="" v-for="(item, index) in questForm.stageList" :key="index">
                    <div class="gsw-box flex-column">
                        <div class="count-box flex-align">
                            <span>{{ questTypes[item.key] }}：共{{ item.count }}个</span>
                        </div>
                        <div class="flex-align">
                            {{ form.resource == 0 ? '总分值' : '总学分' }}：
                            <el-input v-model.number="item.score" size="mini" :disabled='disabled'></el-input>
                            分
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div class="btn" v-show="currentStep == 'step2'">
            <el-button type="primary" @click="prev">上一步</el-button>
            <el-button type="primary" @click="save">{{ disabled ? '返回' : '保存' }}</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            /**
             *  * 索引, 是否涉及排序!?  类型 0 同步课 , 1 异步课 ,3 作业提交 ,4 参与活动 , 5 资源应用
             * 根据评分维度类型的不同 分别代表不同的规则
             * 类型 : 同步课:  0-50 直播  51-99回放
             *  异步课 完成,不同的 时间长度/个数 序号  100-150  为计时   151-199 为计数,
             *  作业提交 300,
             *  参与活动 400参与活动 401创建活动,
             *  资源应用 500上传 501下载 502分享
             */
            disabled: false,
            score: null,
            form: {
                name: '',
                persons: [],
                resource: 0,
                value1: '',
                role: ''
            },
            asyncForm: {
                achievement: '', //达标分数
                detailRules: [{
                    ruleIndex: 101, // 100-150  为计时   151-199 为计数,
                    ruleNum: '', // 观看时长||观看个数
                    ruleValue: '' // 分||学分
                }],
                total: '', //总分值
                type: 1 // 类型
            },
            syncForm: {
                achievement: '',
                detailRules: [{
                    ruleIndex: 0, // 0-50 直播
                    ruleNum: '',
                    ruleValue: ''
                }, {
                    ruleIndex: 51, // 51-99回放
                    ruleNum: '',
                    ruleValue: ''
                }],
                total: '',
                type: 0
            },
            taskForm: {
                achievement: '',
                detailRules: [{
                    ruleIndex: 300, // 300 作业提交
                    ruleNum: '',
                    ruleValue: ''
                }],
                total: '',
                type: 3,
                radio: 1
            },
            joinForm: {
                achievement: '',
                detailRules: [{
                    ruleIndex: 400, // 400 参与活动
                    ruleNum: '',
                    ruleValue: ''
                }, {
                    ruleIndex: 401, // 401 创建活动
                    ruleNum: '',
                    ruleValue: ''
                }],
                total: '',
                type: 4
            },
            resourceForm: {
                achievement: '',
                detailRules: [{
                    ruleIndex: 500, // 500 上传
                    ruleNum: '',
                    ruleValue: ''
                }, {
                    ruleIndex: 501, // 501 下载
                    ruleNum: '',
                    ruleValue: ''
                }, {
                    ruleIndex: 502, // 502 分享
                    ruleNum: '',
                    ruleValue: ''
                }],
                total: '',
                type: 5
            },
            tabPosition: 'task', // 'async',
            currentStep: 'step1',
            evaluateType: 'time',
            person: [],
            rules: {
                totalScore: [{
                    required: true,
                    type: 'number',
                    message: '请输入总分数',
                    trigger: 'blur'
                }]
            },
            workList: [],
            radio: '',
            value: '',
            workForm: {
                achievement: '',
                detailRules: [{
                    ruleIndex: 1500,
                    ruleNum: '',
                    ruleValue: '',
                    valueType: 0
                }],
                total: '',
                type: 15,
                // scoreType: []
            },
            addtendanceForm: {
                stageList: [
                    {
                        stageType: '',
                        score: null,
                        answerCount: null,
                    },
                    {
                        stageType: '',
                        score: null,
                        answerCount: null,
                    }
                ],
            },
            stageTypes: {
                1: '集中阶段',
                2: '跟岗阶段',
                3: '返岗阶段'
            },
            questTypes: {
                1: '前测调研问卷',
                2: '后测调研问卷',
            },
            gswForm: {
                stageList: [
                    {
                        stageType: '',
                        stayCount: '',
                        trackCount: '',
                        stayScore: null,
                        trackScore: null,
                        answerCount: null
                    }
                ],
            },
            questForm: {
                stageList: [{
                    score: null,
                    count: null,
                    key: 1
                }]
            },
            scoreList: [
                { value: 1, label: '取设置分数' },
                { value: 2, label: '取作业分数' }
            ]
        }
    },
    watch: {
        evaluateType(newVal) {
            console.log('评价标准发生改变:', newVal);
            if (newVal == 'time') {
                this.asyncForm.detailRules = [{
                    ruleIndex: 101, // 100-150  为计时   151-199 为计数,
                    ruleNum: '', // 观看时长||观看个数
                    ruleValue: '' // 分||学分
                }]
            } else {
                this.asyncForm.detailRules = [{
                    ruleIndex: 151, // 100-150  为计时   151-199 为计数,
                    ruleNum: '', // 观看时长||观看个数
                    ruleValue: '' // 分||学分
                }]
            }

        }
    },
    methods: {
        next() {
            if (!this.form.name) {
                this.$message({
                    message: '请输入标题',
                    type: 'warning'
                });
                return false
            };
            if (this.form.persons.length == 0) {
                this.$message({
                    message: '请选择适用人员',
                    type: 'warning'
                });
                return false
            };
            if (!this.score) {
                this.$message({
                    message: '请输入总分/学分',
                    type: 'warning'
                });
                return false
            }
            this.currentStep = 'step2';
        },
        prev() {
            this.currentStep = "step1"
        },
        setScore(score) {
            if (!/^\d+(\.\d+)?$/.test(score)) {
                this.$message({
                    message: '请输入正确的分数/学分',
                    type: 'warning'
                });
                this.score = '';
                return false
            }
        },
        save() {
            if (this.disabled) {
                this.$router.go(-1)
            } else {
                let ggzs = { // 跟岗住宿考勤
                    achievement: '',
                    detailRules: [],
                    total: this.addtendanceForm.stageList[0].stayScore,
                    type: 6
                };
                let gggg = { // 跟岗跟岗考勤
                    achievement: '',
                    detailRules: [],
                    total: this.addtendanceForm.stageList[0].trackScore,
                    type: 7
                };
                let jzzs = { // 集中住宿考勤
                    achievement: '',
                    detailRules: [],
                    total: this.addtendanceForm.stageList.length > 1 ? this.addtendanceForm.stageList[1].stayScore : 0,
                    type: 8
                };
                let jzgg = { // 集中跟岗考勤
                    achievement: '',
                    detailRules: [],
                    total: this.addtendanceForm.stageList.length > 1 ? this.addtendanceForm.stageList[1].trackScore : 0,
                    type: 9
                };
                let jzgsw = { // 集中观思问
                    achievement: '',
                    detailRules: [],
                    total: this.gswForm.stageList[0].score,
                    type: this.gswForm.stageList[0].stageType == 1 ? 10 : this.gswForm.stageList[0].stageType == 2 ? 11 : 13
                };
                let gggsw = { // 跟岗观思问
                    achievement: '',
                    detailRules: [],
                    total: this.gswForm.stageList[1] ? this.gswForm.stageList[1].score : 0,
                    type: this.gswForm.stageList[1] ? (this.gswForm.stageList[1].stageType == 1 ? 10 : this.gswForm.stageList[1].stageType == 2 ? 11 : 13) : 0
                };
                let fggsw = { // 返岗观思问
                    achievement: '',
                    detailRules: [],
                    total: this.gswForm.stageList.length > 2 ? this.gswForm.stageList[2].score : '',
                    type: 12
                };
                let beforetest = { // 前测
                    achievement: '',
                    detailRules: [],
                    total:0,
                    type: 13
                };
                let aftertest = { // 后测
                    achievement: '',
                    detailRules: [],
                    total: 0,
                    type: 14
                };
                // let beforetest, aftertest;
                this.questForm.stageList.map(item => {
                    if (item.key == 1) {
                        beforetest.total = item.score
                    } else {
                        aftertest.total = item.score
                    }
                })

                let arr = [this.asyncForm, this.syncForm, this.taskForm, this.joinForm, this.resourceForm, this.workForm, ggzs,
                    gggg, jzzs, jzgg, jzgsw, gggsw, fggsw, beforetest, aftertest];
                let data = {
                    projectId: this.$store.state.user.currentProject.id,
                    type: this.form.resource, //成绩计分方式 0-总分制 1-学分制
                    title: this.form.name, //标题
                    // roles: this.form.persons.includes(0) ? 0 : this.form.persons.join(), //适用人员(角色)
                    roles: 0, //适用人员(角色)
                    positions: this.form.persons.join(), //适用角色(职称)
                    value: this.score, //分值
                    content: JSON.stringify(arr), //规则细则
                    id: this.$route.query.id ? this.$route.query.id : ''
                };
                // console.log('参数:', data);
                console.log('参数:', JSON.parse(data.content));
                let totalScore = 0;
                JSON.parse(data.content).map(item => {
                    if (item.type == 15) {
                        item.detailRules.map(items => {
                            totalScore += items.ruleValue
                        })
                    } else {
                        if (item.total) {
                            totalScore += item.total
                        }
                    }
                })
                console.log('总分:', totalScore);
                if (totalScore != data.value) {
                    this.$message({
                        message: '学分总和必须与设置总成绩相等',
                        type: 'error'
                    });
                    return
                }
                if (this.$route.query.type == 'edit') {
                    this.$Api.Score.editScore(data)
                        .then(res => {
                            console.log('编辑成功:', res);
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                        })
                        .catch(err => {
                            console.log('编辑失败:', err);
                            this.$message.error('编辑失败,请联系管理员');
                        })
                } else {
                    this.$Api.Score.addScore(data)
                        .then(res => {
                            console.log('添加成功:', res);
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                        })
                        .catch(err => {
                            console.log('添加失败:', err);
                            this.$message.error('保存失败,请联系管理员');
                        })
                }
            }
        },
        addItem(type) {
            console.log('type', type)
            // 遍历数组，找到最大的type值
            if (type == 'async') {
                let maxType = this.asyncForm.detailRules.reduce((prev, curr) => {
                    return Math.max(prev, curr.ruleIndex);
                }, 0);
                this.asyncForm.detailRules.push({
                    ruleNum: '',
                    ruleValue: '',
                    ruleIndex: maxType + 1,
                    radio: 1
                });
            } else {
                let maxType = this.workForm.detailRules.reduce((prev, curr) => {
                    return Math.max(prev, curr.ruleIndex);
                }, 0);
                this.workForm.detailRules.push({
                    ruleNum: '',
                    ruleValue: '',
                    ruleIndex: maxType + 1,
                    valueType: 0
                });
            }

        },
        // 获取角色、考勤、观思问、问卷数量
        getRoles() {
            let data = {
                projectId: this.$store.state.user.currentProject.id,
            }
            this.$Api.Score.getRoles(data)
                .then(res => {
                    console.log('角色列表:', res);
                    let arr = [];
                    Object.keys(res.data.titleMap).forEach(key => {
                        arr.push({
                            value: key,
                            label: res.data.titleMap[key]
                        })
                    })
                    this.person = arr;
                    this.addtendanceForm.stageList = res.data.dataInfo.filter(item => {
                        return item.stageType !== 3
                    });
                    this.gswForm.stageList = res.data.dataInfo;
                    this.questForm.stageList = res.data.questInfo;
                    this.check();
                })
                .catch(err => {
                    console.log('获取角色失败:', err)
                })
        },
        // 查询学分数据
        check() {
            let data = {
                id: this.$route.query.id
            }
            this.$Api.Score.viewScore(data)
                .then(res => {
                    console.log('查看结果:', res);
                    this.form.name = res.data.title;
                    // this.form.persons = res.data.position.split(',');
                    this.form.persons = res.data.positions.toString().split(',');
                    this.form.role = res.data.position ? res.data.position.toString() : '';
                    this.form.resource = res.data.type;
                    this.score = res.data.value;
                    let content = JSON.parse(res.data.content);
                    console.log('规则明细:', content);
                    this.asyncForm = content[0];
                    this.syncForm = content[1];
                    this.taskForm = content[2];
                    this.joinForm = content[3];
                    this.resourceForm = content[4];
                    this.workForm = content[5];

                    // 回显考勤
                    // this.$set(this.addtendanceForm.stageList[0], 'stayScore', content[6].total);
                    // this.$set(this.addtendanceForm.stageList[0], 'trackScore', content[7].total);
                    // if (this.addtendanceForm.stageList.length > 1) {
                    //     this.$set(this.addtendanceForm.stageList[1], 'stayScore', content[8].total);
                    //     this.$set(this.addtendanceForm.stageList[1], 'trackScore', content[9].total);
                    // }


                    // this.$set(this.addtendanceForm.stageList[0], 'stayScore', content[8].total);
                    // this.$set(this.addtendanceForm.stageList[0], 'trackScore', content[9].total);
                    // if (this.addtendanceForm.stageList.length > 1) {
                    //     this.$set(this.addtendanceForm.stageList[1], 'stayScore', content[6].total);
                    //     this.$set(this.addtendanceForm.stageList[1], 'trackScore', content[7].total);
                    // }

                    this.addtendanceForm.stageList.map((item, index) => {
                        if (item.stageType == 1) {
                            this.$set(this.addtendanceForm.stageList[index], 'stayScore', content.find(items => items.type == 8).total);
                            this.$set(this.addtendanceForm.stageList[index], 'trackScore', content.find(items => items.type == 9).total);
                        } else if (item.stageType == 2) {
                            this.$set(this.addtendanceForm.stageList[index], 'stayScore', content.find(items => items.type == 6).total);
                            this.$set(this.addtendanceForm.stageList[index], 'trackScore', content.find(items => items.type == 7).total);
                        }
                    })


                    // 回显观思问
                    this.$set(this.gswForm.stageList[0], 'score', content[10].total);
                    if (this.gswForm.stageList.length > 1) {
                        this.$set(this.gswForm.stageList[1], 'score', content[11].total);
                    }
                    if (this.gswForm.stageList.length > 2) {
                        this.$set(this.gswForm.stageList[2], 'score', content[12].total);
                    }
                    // 回显前后测
                    // this.$set(this.questForm.stageList[0], 'score', content[13].total);
                    // if (this.questForm.stageList.length > 1) {
                    //     this.$set(this.questForm.stageList[1], 'score', content[14].total);
                    // }
                    this.questForm.stageList.map((item, index) => {
                        if (item.key == 1) {
                            this.$set(this.questForm.stageList[index], 'score', content.find(items => items.type == 13).total)
                        } else {
                            this.$set(this.questForm.stageList[index], 'score', content.find(items => items.type == 14).total)
                        }
                    })

                })
                .catch(err => {
                    console.log('查看失败:', err)
                })
        },
        // 学校列表
        async listByProject() {
            let data = {
                projectId: this.$store.state.user.currentProject.id
            }
            let resData = await this.$Api.Score.listByProject(data);
            // console.log('学校列表:', resData);
            this.workList = resData.data
        },
        // 删除作业
        delItem(index) {
            this.workForm.detailRules.splice(index, 1);
        }
    },
    mounted() {
        this.getRoles();
        this.listByProject();
        if (this.$route.query.type == 'view') {
            this.disabled = true;
        }
    },
};
</script>
<style lang='less' scoped>
.addScore {
    margin-bottom: 100px;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px 0px #f5f8fa;
    border-radius: 8px;
    padding: 0 60px;

    .step1 {
        width: 900px;
        margin: 30px auto;

        /deep/ .el-input__inner {
            width: 300px;
        }

        .scoreBox {
            margin-left: 80px;
            width: 150px;
            margin-right: 10px;
            margin-bottom: 20px;

            span {
                display: inline-block;
                margin-left: 10px;
                flex-shrink: 0;
            }

            /deep/ .el-input__inner {
                width: 100%;
            }
        }
    }

    .step2 {
        width: 900px;
        margin: 30px auto;
        min-height: 400px;

        .el-input {
            width: 100px;
            margin-right: 10px;
        }


        .menu {
            margin: 0 auto;
        }

        .itemBox {
            .rule_item {
                display: flex;

                /deep/ .el-form-item__content {
                    margin-left: 0 !important
                }
            }

            i {
                cursor: pointer;
                margin-left: 10px;
                font-size: 26px;
                color: #409EFF;
            }
        }

        // /deep/ .el-form-item__label {
        //     width: 100px !important // flex-shrink: 0;
        // }

        // /deep/ .el-form-item__content {
        //     margin-left: 100px !important;
        // }
        .sync-box {
            .complete {
                flex-shrink: 0;
            }

            /deep/ .el-input__inner {
                width: 50px;
            }
        }


        .work-box {
            .el-select {
                margin-right: 20px;
            }

            i {
                cursor: pointer;
                margin-left: 10px;
                font-size: 26px;
                color: #409EFF;
            }

            /deep/ .el-input__inner {
                width: 100%;
            }
        }

        .gengang-box {
            margin-left: 50px;

            /deep/ .el-input__inner {
                width: 100%;
            }
        }

        .gsw-box {
            margin-left: 50px;

            .count-box {
                span {
                    display: block;
                    margin-right: 20px;
                }
            }

            /deep/ .el-input__inner {
                width: 100%;
            }
        }
    }

    .btn {
        margin-bottom: 20px;
        text-align: center;
    }

    /deep/ .el-scrollbar__view {
        max-width: 300px;
        background-color: red !important;
    }

    /deep/ .el-select-dropdown {
        max-width: 400px !important;
    }
}
</style>