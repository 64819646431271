<template>
  <div id="home" class="flex-column">
    <div class="home-wrapper">
      <div class="module-switch">
        <div class="switch-btn-wrap" :class="{'active':activeSwitch == index}" v-for="(item,index) in switchList"
          :key="index" @click="toMainPage(index)">
          <button class="switch-btn csp">{{item}}</button>
        </div>
      </div>
      <template v-if="currentProject.id ==735 && role =='teacher'||ignoreProjectIds.includes(currentProject.id)"></template>
      <statisticsCount v-else :projectId='currentProject.id'></statisticsCount>
      <basicsInfo v-if=" role != 'manager' && role != 'leader' && role != 'school'">
      </basicsInfo>
      <div class="container flex-layout">
        <template v-if="currentProject.id ==735 && role =='teacher'||ignoreProjectIds.includes(currentProject.id)"></template>
        <sidebar v-else></sidebar>
        <router-view class="router-view"></router-view>
      </div>
    </div>
    <ClassTips></ClassTips>
  </div>
</template>
<script>
  import {
    mapState,
  } from "vuex";
  import basicsInfo from "./baisc-info.vue" // 专家、教师身份个人信息及切换
  import statisticsCount from "./statistics-count.vue" //上部分数据统计
  import sidebar from "./sidebar.vue" //左侧导航栏 领导、管理员身份个人信息及切换
  export default {
    name: "home",
    data() {
      return {
        switchList: ['项目主页', '研修空间'],
        activeSwitch: 1,
        ignoreProjectIds:[735,759,762,,765,772,775,778,781,784,787,791]
      };
    },
    computed: {
      ...mapState({
        role: state => state.user.role,
        currentProject: state => state.user.currentProject,
        hasMain: state => state.basics.hasMain
        // count(state) {
        //   console.log(state);
        // }
      })
    },
    components: {
      basicsInfo,
      statisticsCount,
      sidebar
    },
    methods: {
      toMainPage(index) {
        if (index) return;
        // if (!JSON.parse(sessionStorage.getItem('hasMain'))) {
        if (!this.hasMain) {
          this.$message({
            duration: 1000,
            message: '该项目暂无项目主页',
            type: 'error'
          });
          return;
        }
        this.$router.push({
          path: `/main/${this.currentProject.id}/project`
        })
      },

    },
    mounted() {},
    activated() {},
    deactivated() {
      this.activeSwitch = 1;
    },
    // created() {}
  };
</script>

<style lang="less" scoped>
  .home-wrapper {
    width: 990px;
    min-width: 990px;
    margin: 25px auto 100px;
    padding: 0 25px;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px 0px #F5F8FA;
    border-radius: 8px;
  }

  .container {
    flex: 1;
    align-items: flex-start;
  }

  .router-view {
    flex: 1;
    box-shadow: 0px 2px 10px 0px #F5F8FA;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .module-switch {
    background-color: #fff;
    border-bottom: 1px solid #F0F0F0;
    line-height: 40px;

    margin: 0px auto 25px;

    .switch-btn-wrap {
      display: inline-block;
      position: relative;

      .switch-btn {
        line-height: 53px;
        font-size: 13px;
        padding: 0 42px;
        position: relative;
      }
    }

    .active {
      .switch-btn {
        color: #508EF9;
      }

      .switch-btn::before {
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: -2px;
        left: 0;
        background-color: #508EF9;
      }
    }

  }

  @media screen and (min-width: 1250px) {
    .home-wrapper {
      width: 1200px;
      padding: 0 30px;
    }

    .module-switch {
      line-height: 48px;
      margin: 31px auto;

      .switch-btn-wrap {
        .switch-btn {
          line-height: 48px;
          font-size: 15px;
          padding: 0 51px;
        }
      }
    }
  }
</style>