//证书下载
const Certificate = () =>
    import("../views/externalPage/certificate.vue");
export default [{
    path: "/Certificate1",
    name: "Certificate1",
    component: Certificate,
    meta: {
        title: "证书列表",
        hasNotHeader: true,
        keepAlive: false
    }
}, ];