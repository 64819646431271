<template>
    <div class="project_share">
        <div class="manage-wrapper">
            <div class="project-share-container">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="课程共享" name="first">
                        <el-form ref="form" :model="form" label-width="180px">
                            <el-form-item label="课程名称：" :required="true">
                                {{ form.name || '' }}
                            </el-form-item>
                            <div v-for="(item, index) in counterList" :key="index">
                                <el-form-item label="项目：" :required="true">
                                    <el-select v-model="item.projectId" placeholder="请选择项目" filterable
                                        @change="changeProject(item.projectId, item.counter)" :disabled="!isEdit">
                                        <el-option :label="each.name" :value="each.id" v-for="(each, index) in projectList"
                                            :key="index"></el-option>
                                    </el-select>
                                    <i class="el-icon-plus" @click="addShare" v-if="item.counter == 0 && isEdit"></i>
                                    <span class="delete-range csp" @click.stop="deleteShare(index, item)"
                                        v-else-if="item.counter != 0 && isEdit">删除</span>
                                </el-form-item>
                                <el-form-item label="用户组：" :required="true">
                                    <el-cascader style="width:530px" :options="item.groupInfo" v-model="item.userGroupIds"
                                        :props="{ multiple: true, value: 'id', emitPath: false }" clearable filterable
                                        collapse-tags :disabled="!isEdit"></el-cascader>
                                </el-form-item>
                                <el-form-item label="课节名称：" :required="true">
                                    <el-transfer v-model="item.lessonIds" :data="lessonList" :titles="['未分享课节', '已分享课节']"
                                        :button-texts="['取消', '分享']"
                                        :props="{ key: 'id', label: 'name', disabled: 'disabled' }" :disabled="!isEdit">
                                    </el-transfer>
                                </el-form-item>
                            </div>
                            <el-form-item class="el-form-item-btns" label-width="0">
                                <el-button type="primary" @click="goEdit" class="edit" v-if="!isEdit">编辑</el-button>
                                <el-button type="primary" @click="submit" class="edit" v-if="isEdit">确定</el-button>
                                <el-button type="primary" @click="cancel" class="cancel" v-if="isEdit">取消</el-button>
                            </el-form-item>

                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
import {
    groupBy
} from 'lodash/collection'
import {
    mapState
} from "vuex";
export default {
    data() {
        return {
            activeName: "first",
            form: {}, //表单信息
            projectList: [], //项目列表
            isEdit: false, //是否在编辑
            counter: 0, // 计数
            counterList: [{ //组数据列表
                counter: 0,
                projectId: undefined,
                groupInfo: [],
                userGroupIds: [],
                lessonIds: [],
            }],
            lessonList: [], //课节列表
            hasShareInfo: false,
            submitForm: {},
        };

    },
    computed: {
        ...mapState({
            backPath: state => state.basics.breadList[2].path,
        }),
    },
    methods: {
        // 进行编辑
        goEdit() {
            // 按钮文字为  编辑
            if (!this.isEdit) {
                this.isEdit = true;
                let isClassin = this.form.classType == 5
                // console.log('是否为classin课程', isClassin);
                this.lessonList.forEach((item) => {
                    let timeStape = item.lessonsBeginTime - 60 * 20
                    // 课程为classin类型 && 大于课程开始时间 - 20min  不能分享当前课节
                    if (isClassin && (this.dayjs(new Date()).unix() > timeStape)) {
                        item.disabled = true;
                    } else {
                        item.disabled = !this.isEdit;
                    }
                })
            }
        },

        // 取消按钮
        cancel() {
            if (this.isEdit) {
                this.$confirm("确认取消本次编辑？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.isEdit = false;
                        // 不存在 分享数据
                        if (!this.hasShareInfo) {
                            this.counterList = [{ //组数据列表
                                counter: 0,
                                projectId: undefined,
                                groupInfo: [],
                                userGroupIds: [],
                                lessonIds: [],
                            }]
                            this.lessonList.forEach((item) => {
                                item.disabled = !this.isEdit
                            })
                        }
                        //  存在 分享数据
                        if (this.hasShareInfo) {
                            this.getShareCourseInfo();
                        }
                    })
                    .catch(() => { });
            } else {
                this.$router.go(-1)
            }
        },
        // 添加分享
        addShare() {
            this.counter++;
            console.log(this.counter)
            this.counterList.push({
                counter: this.counter,
                projectId: undefined,
                groupInfo: [],
                userGroupIds: [],
                lessonIds: [],
            });
        },
        // 删除
        deleteShare(i, item) {
            this.counterList.splice(i, 1);
        },
        // 提交（确定）按钮 
        submit() {
            this.$confirm("确认提交本次编辑？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    let courseId = this.$route.params.id;
                    let shareInfo = [];
                    this.counterList.map(item => {
                        if (item.lessonIds.length != 0) {
                            shareInfo.push({
                                projectId: item.projectId,
                                courseId: courseId,
                                userGroupIds: item.userGroupIds,
                                lessonIds: item.lessonIds,
                            })
                        }
                    })
                    this.$set(this.submitForm, 'shareInfo', shareInfo)
                    this.$set(this.submitForm, 'courseId', courseId)
                    this.submitShareCourse();
                })
                .catch(() => { });
        },
        // 提交数据
        async submitShareCourse() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            let resData = await this.$Api.Course.shareCourse(this.submitForm);
            console.log(resData);
            if (resData.code == 200) {
                loading.close();
                this.$message.success({
                    message: "分享成功"
                });
                console.log(this.backPath);
                this.$router.push({
                    path: this.backPath
                })
            } else {
                loading.close();
                this.$message.error({
                    title: "错误",
                    message: `分享失败${resData.msg}`
                });
            }

        },
        // 通过id查询课程基本信息
        async getfindCourse() {
            let id = this.$route.params.id;
            let resData = await this.$Api.Course.findCourse(id);
            this.form = resData.data;
        },
        // 查询共享数据
        async getShareCourseInfo() {
            let that = this;
            let params = {
                courseId: this.$route.params.id
            }
            let resData = await this.$Api.Course.getShareCourseInfo(params);
            console.log('共享数据', resData)
            if (JSON.stringify(resData.data) !== '[]') {
                console.log('分组', groupBy(resData.data, 'projectId'))
                this.hasShareInfo = true;
                this.counterList = [];
                let groupByList = groupBy(resData.data, 'projectId');
                // let groupByList = resData.data.find(item=>item.projectId==);
                console.log('groupByList---:',groupByList)
                this.counter = groupByList.length - 1;
                for (const key in groupByList) {
                    let groupIdx = 0;
                    let lessonIds = []
                    groupByList[key].map((item, idx) => {
                        lessonIds.push(item.lessonId);
                    })
                    that.counterList.push({
                        counter: groupIdx,
                        projectId: groupByList[key][0].projectId,
                        userGroupIds: groupByList[key][0].groupIds,
                        lessonIds: lessonIds
                    })
                    groupIdx++
                }

                this.counterList.map((item, idx) => {
                    that.changeProject(item.projectId, idx);
                })
            }
        },
        // 获取项目列表
        async allProjectList() {
            let resData = await this.$Api.Course.allProjectList();
            console.log('项目列表', resData)
            this.projectList = resData.data;
        },
        // 选择项目
        changeProject(id, counter) {
            console.log(id,counter);
            this.getGroupInfo(id, counter);
        },
        // 获取用户组选项
        async getGroupInfo(id, counter) {
            let resData = await this.$Api.Course.GroupInfo(id);
            console.log('用户组', resData.data)
            this.counterList.map(item => {
                if (item.counter == counter) {
                    // item.groupInfo = resData.data;
                    this.$set(item, 'groupInfo', resData.data);
                }
            })
        },
        // 获取课节列表
        async getLessonList() {
            let params = {
                page: 1,
                limit: 1000,
                courseId: this.$route.params.id
            };
            let resData = await this.$Api.Course.getExpertLesssonList(params);
            this.lessonList = resData.data.list;
            this.lessonList.forEach((item) => {
                item.disabled = !this.isEdit
            })

            console.log('课节列表', this.lessonList);
        },
    },
    mounted() {
        window.scroll(0, 0)
        this.getShareCourseInfo();
        this.getfindCourse();
        this.allProjectList();
        this.getLessonList();
    },
};
</script>

<style lang="less" scoped>
@import "./less/manage-project.less";

.project-share-container {
    .introduce {
        padding-right: 40px;
        word-wrap: break-word;
        word-break: break-all;

    }

    .el-icon-plus {
        color: #333;
        font-size: 14px;
        font-weight: 600;
        margin-left: 15px;
        cursor: pointer;
        padding: 2px;
        border: 1px solid transparent;
        border-radius: 50%;
        transition: 1s all;

        &:hover {
            border: 1px solid #333;
        }
    }

    .delete-range {
        color: #508EF9;
        margin-left: 17px;
        align-self: flex-start;
        margin-top: 10px;

        &:hover {
            color: rgba(80, 142, 249, .7);
        }
    }

    /deep/ .el-form-item-btns .edit {
        color: #fff;
        margin-left: 0;
    }

}
</style>