<template>
    <div class="main-page-entrance">
        <div class="banner-wrapper">
            <el-carousel indicator-position="none">
                <el-carousel-item class="default-banner">
                    <div>
                        <h2 class="banner-title" v-if="$route.params.id==266">2022年内蒙古自治区中部四盟市三科统编教材教师培训项目</h2>
                        <h2 class="banner-title" v-else>{{currentProject.name || '--'}}</h2>
                        <p class="banner-subtitle wes">{{currentProject.introduction || ''}}</p>
                    </div>
                </el-carousel-item>
                <!-- <el-carousel-item v-for="item in 4" :key="item">
                    <h3 class="small">{{ item }}</h3>
                </el-carousel-item> -->
            </el-carousel>
            <div class="switchs-tab">
                <div class="tabs-inner">
                    <button class="switch-btn csp" :class="{'active':!active}" @mouseenter="active = 0">项目主页</button>
                    <button class="switch-btn csp" :class="{'active':active}" @mouseenter="active = 1"
                        @mouseleave="active=0" @click="toSpace">研修空间</button>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    export default {
        data() {
            return {
                active: 0,
                projectName: undefined,
                projcetIntroduce: undefined
            }
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
            })
        },
        methods: {
            async getProjectInfo() {
                this.projectName = ''
                let resData = await this.$Api.Home.getProjectInfo(this.$route.params.id);
                if (JSON.stringify(resData.data) == '{}' && !resData.data) return;
                this.projectName = resData.data.name
                this.projcetIntroduce = resData.data.introduction
            },
            // 去研修空间
            toSpace() {
                let role = this.$store.state.user.role;
                let pathMap = {
                    'parent': "/home/schedule", //家长
                    'normal': "/home/schedule", //师范生
                    'teacher': this.currentProject.id == 765||this.currentProject.id == 759||this.currentProject.id == 762||this.currentProject.id == 735?"/home/mytask":"/home/schedule", //老师
                    'student': "/home/schedule", //学生
                    'expert': "/home/schedule", //专家
                    'assistant': "/home/schedule", //助教
                    'school': "/home/leader_home", //学校
                    'leader': "/home/leader_home", //领导（管理者）
                    'manager': "/home/weekly", //管理员
                }
                this.$router.push(pathMap[role]);
            },
        },
        activated() {
            // this.getProjectInfo();
        },
        deactivated() {
            this.active = 0;
            // this.projectName = undefined;
            // this.projcetIntroduce = undefined;
        }
    }
</script>

<style lang="less" scoped>
    @font-face {
        font-family: 'ktf';
        src: url("../../../static/font/ktf.TTF");
    }

    .main-page-entrance {
        background-color: #fff;
        line-height: 1;

        .banner-wrapper {
            background-color: #1769FA;
            position: relative;

            /deep/ .el-carousel__container {
                height: 430px;
            }

            .default-banner {
                width: 100%;
                height: 100%;
                text-align: center;
                color: #fff;
                background-image: url("~@/assets/images/main/banner.png");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;

                .banner-title {
                    margin-top: 120px;
                    font-size: 50px;
                    margin-bottom: 43px;
                    font-family: 'ktf';
                }

                .banner-subtitle {
                    font-size: 17px;
                    margin: 0 auto;
                    width: 990px;
                }
            }
        }

        .switchs-tab {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 999;
            background: rgba(255, 255, 255, 0.2);

            .tabs-inner {
                width: 990px;
                margin: 0 auto;
                padding: 20px 0;
            }

            .switch-btn {
                color: #fff;
                line-height: 31px;
                padding: 0 30px;
                border: 1px solid transparent;
                margin-right: 34px;
                font-size: 13px;


                &.active {
                    border: 1px solid #fff;
                    border-radius: 25px;
                    position: relative;
                }
            }
        }

        @media screen and (min-width:1250px) {
            .banner-wrapper {
                /deep/ .el-carousel__container {
                    height: 520px;
                }

                .default-banner {

                    .banner-title {
                        margin-top: 145px;
                        font-size: 60px;
                        margin-bottom: 52px;
                    }

                    .banner-subtitle {
                        font-size: 20px;
                        width: 1200px;
                    }
                }
            }


            .switchs-tab {
                .tabs-inner {
                    width: 1200px;
                    padding: 24px 0;
                }

                .switch-btn {
                    line-height: 38px;
                    padding: 0 36px;
                    margin-right: 44px;
                    font-size: 16px;

                    &.active {
                        border: 1px solid #fff;
                        border-radius: 25px;
                        position: relative;
                    }
                }
            }
        }
    }
</style>