<template>
    <div class="certificate">
        <div class="logo">
            <img src="@/assets/images/certificate/logo-B1fkTwuY.jpg" alt="" />
            <span>北京市“雅言传承文明 经典浸润人生”语言文字系列活动</span>
        </div>
        <h1>北京市2024年“雅言传承文明 经典浸润人生”语言文字系列活动获奖证书</h1>
        <div class="certificate_table">
            <div class="switch">
                <div class="flex-align-center" :class="{ 'switch_active': displays == 'stu' }" @click="changeType('stu')">
                    学生证书</div>
                <div class="flex-align-center" :class="{ 'switch_active': displays == 'ter' }" @click="changeType('ter')">
                    教师证书</div>
            </div>
            <table border="1">
                <thead>
                    <tr>
                        <th :colspan="displays == 'stu' ? 7 : 6">
                            <div class="search">
                                <el-input placeholder="请输入内容" v-model="searchTerm" size="small" clearable
                                    @clear="handleClear">
                                    <el-button slot="append" icon="el-icon-search" @click="Search"></el-button>
                                </el-input>
                            </div>
                        </th>
                    </tr>
                    <tr class="type">
                        <th style="text-align: center;" :class="item.type"
                            v-for="(item, index) in displays == 'stu' ? nav_stu : nav_ter" :key="index">
                            {{ item.name }}</th>
                    </tr>
                </thead>
                <tbody v-if="dynamicsData.length != 0 && displays == 'stu'">
                    <tr v-for="(item, index) in dynamicsData" :key="index">
                        <td>{{ item.CertificateNo }}</td>
                        <td>{{ item.Sname }}</td>
                        <td>{{ item.Awards }}</td>
                        <td>{{ item.ActivityName }}</td>
                        <td>{{ item.Instructor }}</td>
                        <td>{{ item.SchoolName }}</td>
                        <td>
                            <span style="color: #409EFF; cursor: pointer;"
                                @click="downloadImage(item.CertificateNo, item.operate)">下载
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td :colspan="displays == 'stu' ? 7 : 6">
                            <div class="paging">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                    :current-page.sync="currentPage3" :page-size="15"
                                    layout="total,prev, pager, next, jumper" :total="total" :background="true">
                                </el-pagination>
                            </div>
                        </td>

                    </tr>
                </tbody>
                <tbody v-else-if="dynamicsData.length != 0 && displays == 'ter'">
                    <tr v-for="(item, index) in dynamicsData" :key="index">
                        <td>{{ item.CertificateNo }}</td>
                        <td>{{ item.Sname }}</td>
                        <td>{{ item.Awards }}</td>
                        <td>{{ item.ActivityName }}</td>
                        <td>{{ item.SchoolName }}</td>
                        <td>
                            <span style="color: #409EFF; cursor: pointer;"
                                @click="downloadImage(item.CertificateNo, item.operate)">下载
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td :colspan="displays == 'stu' ? 7 : 6">
                            <div class="paging">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                    :current-page.sync="currentPage3" :page-size="15"
                                    layout="total,prev, pager, next, jumper" :total="total" :background="true">
                                </el-pagination>
                            </div>
                        </td>

                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td :colspan="displays == 'stu' ? 7 : 6">暂无数据</td>
                    </tr>
                </tbody>

            </table>

        </div>
        <div class="sign flex-column-center">
            <p>Copyright: © 2019-2022北京双优云桥教育科技有限公司</p>
            <p>网址： yun.cnuen.net</p>
            <p class="flex-align">
                <img style="margin-right: 10px;" src="@/assets/beian.png" alt="">
                <a
                    href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802034806">京公网安备11010802034806号</a>
                <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">京ICP备：17019635号-3</a>
            </p>
        </div>

    </div>
</template>

<script>
import certificateStu from "@/assets/json/certificateStu.json";
import certificateTer from "@/assets/json/certificateTer.json";
export default {
    //import引入组件才能使用
    components: {},
    props: {},
    data() {
        return {
            searchTerm: '',
            currentPage3: 1,
            newdata: [],
            dynamicsData: [],
            total: 0,
            size: 15,
            SearchType: false,
            filterData: [],
            displays: 'stu',
            nav_stu: [
                {
                    name: '证书编号',
                    type: 'dimensions1',
                },
                {
                    name: '学生姓名',
                    type: 'dimensions2',
                },
                {
                    name: '奖项',
                    type: 'dimensions2',
                },
                {
                    name: '活动名称',
                    type: 'dimensions1',
                },
                {
                    name: '指导教师',
                    type: 'dimensions2',
                },
                {
                    name: '学校名称',
                    type: 'dimensions3',
                },
                {
                    name: '操作',
                    type: 'dimensions4',
                },
            ],
            nav_ter: [
                {
                    name: '证书编号',
                    type: 'dimensions1',
                },
                {
                    name: '教师姓名',
                    type: 'dimensions2',
                },
                {
                    name: '奖项',
                    type: 'dimensions2',
                },
                {
                    name: '活动名称',
                    type: 'dimensions3',
                },
                {
                    name: '学校名称',
                    type: 'dimensions3',
                },
                {
                    name: '操作',
                    type: 'dimensions4',
                },
            ]
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        //当前页显示数据
        handleCurrentChange(val) {
            if (this.SearchType) {
                this.dynamicsData = this.filterData.slice(this.paginatedData().start, this.paginatedData().end);
            } else {
                this.dynamicsData = this.newdata.slice(this.paginatedData().start, this.paginatedData().end);
            }

        },
        //搜索后显示数据
        Search() {
           
            this.filterData = this.filteredData();
            
            
            this.currentPage3 = 1;
            this.total = this.filterData.length;
            this.dynamicsData = this.filterData.slice(this.paginatedData().start, this.paginatedData().end);
        },
        //搜索条件
        filteredData(type) {
            let data = this.displays == 'stu' ? certificateStu.data : certificateTer.data;
           
            const searchTerm = this.searchTerm.toLowerCase();
          
            if (searchTerm == '') {
                this.SearchType = false;
                return data;
            } else {
               
                if (/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(searchTerm)) {
                  
                    this.SearchType = true;
                    return data.filter(item =>
                        Object.values(item).some(value =>
                            value.toString().toLowerCase().includes(searchTerm)
                        )
                    );
                } else {
                    return [];
                }
            }
        },
        //分页取值范围
        paginatedData() {
            const start = (this.currentPage3 - 1) * this.size;
            const end = start + this.size;
            return {
                start: start,
                end: end
            }
        },
        //清空搜索
        handleClear() {
            this.currentPage3 = 1;
            this.SearchType = false;
            this.dataSources();
        },
        //切换数据源
        changeType(type) {
            this.searchTerm = '';
            this.handleClear();
            this.displays = type;
            this.dataSources();
        },
        //确定数据来源
        dataSources() {
            this.newdata = this.displays == 'stu' ? certificateStu.data : certificateTer.data;
            this.dynamicsData = this.newdata.slice(this.paginatedData().start, this.paginatedData().end);
            this.total = this.newdata.length;
        },
        //图片下载
        async downloadImage(id, data) {
            const imageUrl = data;
            try {
                const response = await fetch(imageUrl);
                if (!response.ok) {
                    throw new Error('图片下载失败');
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = id + 'image.png';
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('下载图片时出错:', error);
            }
        }
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {
        this.changeType('stu')

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
    
    },
}
</script>
<style scoped lang="less">
.certificate {
    width: 100%;
    min-width: 1200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h1 {
        font-size: 19px;
        margin-bottom: 50px;
        font-weight: 600;
    }

    .logo {
        // width: 1068px;
        width: 1366px;
        margin-bottom: 80px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // border: 1px solid #000;

        img {
            width: 50px;
            height: auto;
            margin-left: 30px;
        }

        span {
            margin-left: 20px;
            cursor: default;
            color: #333;
            font-size: 21px;
            font-weight: bold;
            flex-shrink: 0;
            font-family: SimSun, 宋体, serif;
        }
    }

    .certificate_table {
        width: 1068px;
        // min-height: 600px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        padding: 25px;
        border-radius: 11px;

        .switch {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // border: 1px solid #000;
            width: 200px;

            div {
                width: 100px;
                height: 40px;
                font-size: 15px;
                border: 1px solid transparent;
                border-bottom: none;
                cursor: pointer;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                margin-bottom: -1px;
                transition: all .2s;
                font-weight: bold;
            }

            .switch_active {
                border: 1px solid rgba(0, 0, 0, 0.125);
                border-bottom: none;
                background-color: #fafafa;
                color: #409EFF;
            }
        }

        table {
            border-collapse: collapse;
            width: 100%;

            thead {
                background-color: #fafafa;

                tr {
                    .search {
                        width: 320px;
                        float: right;
                    }
                }
            }

            .paging {
                float: left;
            }

        }

        th,
        td {
            border: 1px solid #e6e6e6;
            text-align: center;
            padding: 9px 15px;
            min-height: 20px;
            line-height: 20px;
            font-size: 14px;
        }
    }

    .sign {
        width: 1068px;
        height: 100px;
        margin-top: 60px;
    }
}

.dimensions1 {
    min-width: 100px;
}

.dimensions2 {
    min-width: 60px;
}

.dimensions3 {
    min-width: 120px;
}

.dimensions4 {
    min-width: 50px;
}
</style>